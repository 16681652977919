.quote-table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: darkmagenta;
    color: white;
}

tr:nth-child(even) {
    background-color: cornsilk;
}

tr:hover {
    background-color: lightgray;
}

a {
    color: darkmagenta;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.filter-item {
    margin-right: 1rem;
}

