/* Full-page overlay */
.loading-overlay {
    position: fixed;  /* Fixed to cover the whole viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey */
    z-index: 9999;  /* Ensure it's on top of everything */

    /* Flexbox to center the content */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Flexbox container for spinner and message */
.loading-content {
    display: flex;
    flex-direction: column;  /* Stack spinner and message vertically */
    align-items: center;
}

/* Spinner styling */
.spinner {
    height: 60px;
    width: 60px;
    border-left: 6px solid rgba(0, 174, 239, 0.15);
    border-right: 6px solid rgba(0, 174, 239, 0.15);
    border-bottom: 6px solid rgba(0, 174, 239, 0.15);
    border-top: 6px solid rgba(0, 174, 239, 0.8);
    border-radius: 100%;
    animation: rotation 0.6s infinite linear;
    margin-bottom: 20px; /* Space between spinner and text */
}

/* Spinner animation */
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Text styling */
.spinner-message {
    text-align: center;
}

.loading-text {
    color: white;
    font-size: 1.5rem;
    margin: 0;
}

/* Responsive - smaller devices */
@media screen and (max-width: 768px) {
    .spinner {
        height: 40px;
        width: 40px;
    }

    .loading-text {
        font-size: 1.2rem;
    }
}