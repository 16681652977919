.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.close-button {
    position: absolute;
    top: -5px;
    right: 5px;
    border: none;
    background: transparent;
    font-size: 1.5em;
    cursor: pointer;
    color: #000;
    padding: 5px;
    transition: color 0.2s;
}

.close-button:hover {
    color: #ff0000;
}