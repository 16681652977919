:root {
  --color-primary: #264653; /* For buttons and primary elements */
  --color-secondary: #2a9d8f; /* For additional elements */
  --color-tertiary: #e9c46a;
  --color-quaternary: #f4a261;
  --color-danger: #e76f51; /* For danger elements */
  --background-light: #ffffff;
  --background-dark: #000000;
}

body {
  background-color: var(--background-light);
  color: var(--color-primary);
}

button {
  border: none;
  border-radius: 4px;
  color: #ffffff;
}

button:hover {
  opacity: 0.8;
}

.add-line-item-button {
  background-color: var(--color-secondary);
}

.add-multiple-sku-button {
  background-color: var(--color-secondary)
}

.quantity-add-button {
  background-color: var(--color-secondary);
  height: 30px; /* Match the height of the input field */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.quantity-subtract-button {
  background-color: var(--color-danger);
  height: 30px; /* Match the height of the input field */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.customer-search-button {
  margin-left: 10px; /* Adjust this value as needed */
}

.form-group {
  position: relative;
}

.form-group .form-feedback {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.sale-price {
  color: red !important;
  font-weight: bold;
}

.currency-dropdown {
  width: 100%;
  max-width: 150px;
  margin-left: 10px;
  display: inline-block;
}

.customer-search-button:hover,
.new-customer-button:hover {
  background-color: var(--color-secondary);
  opacity: 0.8;
}

.today-button,
.thirty-day-button,
.ninety-day-button,
.create-quote-button,
.title-search-button {
  background-color: var(--color-primary);
  color: #ffffff;
  border: none;
  border-radius: 4px;
}

.title-search-button:hover {
  background-color: var(--color-secondary);
  opacity: 0.8;
}

.taxable-button,
.not-taxable-button {
  background-color: var(--background-light);
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.taxable-button.active,
.not-taxable-button.active {
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #ffffff;
}

.taxable-button:hover,
.not-taxable-button:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #ffffff;
}

.taxable-button.active:hover,
.not-taxable-button.active:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

input[type="text"],
input[type="number"],
textarea {
  border: 1px solid var(--color-primary);
  height: 30px; /* Input Field Size */
}

.qt-input-style{
  border: 1px solid var(--color-primary);
  height: 30px; /* Input Field Size */
}

.bootstrap-input{
  border:  1px solid #dee2e6!important;
  height: 35px !important;
}

.quote-totals-table-container {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}

.table td {
  vertical-align: middle;
}

.text-end {
  text-align: right;
}

.dark-mode {
  background-color: var(--background-dark);
  color: #ffffff;
}

.dark-mode button {
  background-color: var(--color-secondary);
  color: #ffffff;
}

.dark-mode input[type="text"],
.dark-mode input[type="number"],
.dark-mode textarea {
  border: 1px solid var(--color-secondary);
}

.dark-mode .form-control {
  background-color: var(--background-dark);
  color: #ffffff;
}

.dark-mode .form-control::placeholder {
  color: #b0b0b0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Address Style */
.address-container {
  margin-left: 0; /* Aligns the address block to the left */
  max-width: 100%; /* Extends the address to full width, may be ugly can remove to revert size */
  margin-bottom: 10px; /* Add in some gap to the Product Form */
}

/* End Address Style */

/* Navbar Styles */

.navbar-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-links {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  list-style: none;
}

.navbar-link {
  padding: 10px;
  text-decoration: none;
  color: white;
}

.navbar-link:hover {
  color: var(--color-secondary);
}

.navbar-search {
  position: relative;
  display: inline-block;
}

.search-input {
  padding-left: 30px !important;
  border-radius: 0.25rem;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 8px;
  color: #aaa;
}

/* End Navbar Style */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* TODO REFACTOR Move this to a different CSS where it makes more sense. I don't want to dump too much CSS in here */
.quote-tool-title {
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
}
/* Begin Action Icon Styles */

.action-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-icon {
  margin: 0 5px;
}

/* End Action Icon Styles */

/* Auth Page */

.auth-box {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 576px) {
  .auth-box {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .auth-box {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .auth-box {
    max-width: 400px;
  }
}

/* End Auth Page */

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

/* New styles for inline badge */
.sku-label {
  display: flex;
  align-items: center;
  position: relative;
}

.duplicate-sku-badge {
  margin-left: 10px;
  margin-top: 7px;
}

.archived-sku-badge {
  margin-left: 10px;
  margin-top: 7px;
}

.discount-button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two equal-width columns */
  gap: 2px; /* Tightens the gap between buttons */
  width: 100%;
}

.discount-button-grid button {
  width: 100%; /* Makes sure the buttons fill the grid cells */
  padding: 6px;
  font-size: 0.9em;
}

@media (max-width: 600px) {
  .sku-label {
    flex-direction: column;
    align-items: flex-start;
  }
  .duplicate-sku-badge {
    margin-left: 0;
    margin-top: 5px;
  }
}

/* Consistent spacing and alignment for input groups */
.form-control {
  margin: 0;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
}

.input-group {
  display: flex;
  align-items: center;
  margin: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.quote-line-item-table-compact {
  td {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  input {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .input-group-text {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  tr {
    background-color: lightgray;
  }
}

.profit-positive {
  color: green !important;
  font-style: italic;
}

.profit-negative {
  color: red !important;
  font-weight: bold;
}

.modal-sub-heading {
  font-size: 1rem;
  font-weight: normal;
}

.rounded-button {
  border-radius: 20px;
  padding: 8px 16px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
}

.rounded-button:hover {
  background-color: var(--color-secondary);
}

/* Ensure alignment of quantity buttons */
.input-group-sm .quantity-add-button,
.input-group-sm .quantity-subtract-button {
  height: 30px; /* Match the height of the input field */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px; /* Ensure padding is consistent */
  margin: 0;
}

.small-italic{
  font-size: 11px;
  font-style: italic;
}

.warning-color{
  color: #DAA520;
  font-weight: bold;
}

.warning-badge{
  background-color: #997613;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Align buttons with space between them */
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 10px; /* Add some space between the buttons */
}

.button-container button {
  flex: 1; /* Optional: Make both buttons take up equal space */
}

.small-button{
  padding: 5px;
  font-size: 0.8em;
}

.currency-indicator {
  display: inline-flex;
  justify-content: flex-end;
  margin-left: 10px;
}

#CurrencyRatesInfo {
  font-size: 1.5rem;
  color: #DAA520;
  cursor: pointer;
}

.small-padding-input-group{
  padding-left: 0.2ch;
  padding-right: 0.2ch;
}

.dropdown-item{
  color: #212529 !important;
}

.short-code {
  display: inline-block;
  background-color: #f8d7da; /* Light reddish background */
  color: #842029; /* Dark reddish text */
  font-family: "Courier New", Courier, monospace;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.1rem 0.1rem; /* Padding for a badge look */
  border-radius: 0.25rem; /* Rounded corners */
  border: 1px solid #f5c2c7; /* Border matching background */
  user-select: text; /* Ensures text is selectable */
  white-space: nowrap; /* Prevents multi-line selection */
}

.taxable-conflict{
  margin: 0.3rem;
  font-size: 10pt;

}